import React, { useReducer } from "react";
import reducers from "@/reducers/index";
// import LOGIN_ACTION_TYPES from "../../constant/action-types/login"
const store = React.createContext();
const { Provider } = store;
function StateProvider({ children }) {
    const [state, dispatch] = useReducer(reducers, undefined, reducers);
    return React.createElement(Provider, { value: { state, dispatch } }, children);
}
export { store, StateProvider };
