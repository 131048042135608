import axios from 'axios';
export class CancelHolder {
    constructor() {
        this.cancel = (reason) => {
            var _a, _b;
            (_a = this._source) === null || _a === void 0 ? void 0 : _a.cancel(reason);
            (_b = this._controller) === null || _b === void 0 ? void 0 : _b.abort(reason);
            delete this._source;
            delete this._controller;
        };
        this.ignoreCancelErr = CancelHolder.ignoreCancelErr;
    }
    cancelToken() {
        this._source = this._source || axios.CancelToken.source();
        return this._source.token;
    }
    signal() {
        this._controller = this._controller || new window.AbortController;
        return this._controller.signal;
    }
    signals() {
        if (window.AbortController)
            return { signal: this.signal() };
        else
            return { cancelToken: this.cancelToken() };
    }
}
CancelHolder.isCancel = (err) => axios.isCancel(err);
CancelHolder.ignoreCancelErr = (e) => {
    if (!axios.isCancel(e))
        throw e;
};
