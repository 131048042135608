/******************************************************************
 * Copyright @ 2023 广州牛师帮文化科技有限公司. All rights reserverd.
 * @file   src\routes\Login\logic\usePwdLoginLogic.tsx
 * @author Gim
 * @date   2023/01/11 11:04
 * @desc   账号密码登录逻辑封装
 ******************************************************************/
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PUBLIC_KEY } from '@/constant/public-key';
import { AuthLoginPwd } from '@/api/AuthLoginPwd';
import JSEncrypt from 'jsencrypt';
import { useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { handleLoginSuccess } from './useLoginLogic';
import qs from 'qs';
export function usePwdLoginLogic(options) {
    const history = useHistory();
    const redirect = options === null || options === void 0 ? void 0 : options.redirect;
    const { search } = useLocation();
    const { domainId } = useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }), [search]);
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [isAutoLogin, setIsAutoLogin] = useState(false); // 是否七天自动登录
    const aok = !!userName && password.length >= 4 && password.length <= 32;
    const login = (org) => __awaiter(this, void 0, void 0, function* () {
        const jsencrypt = new JSEncrypt();
        jsencrypt.setPublicKey(PUBLIC_KEY);
        const params = { orgId: org.id, userName, password: '' + jsencrypt.encrypt(password) };
        const { data } = yield AuthLoginPwd.call(params);
        if (!data) {
            throw new Error(`data got ${data}`);
        }
        yield handleLoginSuccess({ loginInfo: data, isAutoLogin, domainId: Number(domainId) });
        if (redirect && typeof redirect === 'string')
            history.replace(redirect);
        return;
    });
    return {
        userName, setUserName,
        password, setPassword,
        isAutoLogin, setIsAutoLogin,
        aok,
        login
    };
}
