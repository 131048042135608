var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export var BlobReader;
(function (BlobReader) {
    function readAsArrayBuffer(blob) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = e => resolve(e.target.result);
                reader.onerror = e => reject(e.target.error);
                reader.readAsArrayBuffer(blob);
            });
        });
    }
    BlobReader.readAsArrayBuffer = readAsArrayBuffer;
    function readAsDataURL(blob) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = e => resolve(e.target.result);
                reader.onerror = e => reject(e.target.error);
                reader.readAsDataURL(blob);
            });
        });
    }
    BlobReader.readAsDataURL = readAsDataURL;
    function readAsText(blob, encoding) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = e => {
                    var _a;
                    if (typeof ((_a = e.target) === null || _a === void 0 ? void 0 : _a.result) === 'string')
                        resolve(e.target.result);
                    else
                        reject(new Error('Blob数据非文本'));
                };
                reader.onerror = e => reject(e.target.error);
                reader.readAsText(blob, encoding);
            });
        });
    }
    BlobReader.readAsText = readAsText;
})(BlobReader || (BlobReader = {}));
