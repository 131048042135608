var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AuthUserInfo_post as AuthUserInfo } from '@/api/edited/AuthUserInfo_post'; // TORNA-API-VISITOR
import { CancelHolder } from '@/network';
import { LiveStorage } from '@nsb/common-tools/dist/LiveStorage';
import { useExpiryObjectStorage } from '@nsb/common-tools/dist/hooks/useExpiryObjectStorage';
import { useEffect } from 'react';
const stroages = {
    loginInfo: LiveStorage.get('expiry_local'),
    userInfo: LiveStorage.get('expiry_local')
};
export function getFullLoginInfo(domainId) {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        const { value, expiry } = stroages.loginInfo.getExpiryItem('login_info');
        const info = _parseJson(value);
        const expireTime = expiry - Date.now();
        const { securityPermissionList: permissions, extensionNo, mobile, email, position, department } = (_a = findUserInfo({ domainId })) !== null && _a !== void 0 ? _a : yield fetchUserInfo({ domainId, authorization: info.tokenValue, expireTime: expireTime });
        const gen_item = (value) => (value === void 0 || value === null) ? null : { value, expiry };
        const ret = {
            token: { value: info[_LoginInfoKeyMap['token']], expiry },
            ticket: { value: info[_LoginInfoKeyMap['ticket']], expiry },
            userId: { value: info[_LoginInfoKeyMap['userId']], expiry },
            orgId: { value: info[_LoginInfoKeyMap['orgId']], expiry },
            name: { value: info[_LoginInfoKeyMap['name']], expiry },
            permissions: gen_item(Array.isArray(permissions) ? permissions.map(v => {
                var _a, _b;
                return ({
                    id: v.id,
                    name: v.name,
                    label: (_a = v.remark) !== null && _a !== void 0 ? _a : '',
                    remark: (_b = v.remark) !== null && _b !== void 0 ? _b : '',
                    url: v.url,
                });
            }) : null),
            extensionNo: gen_item(extensionNo),
            mobile: gen_item(mobile),
            email: gen_item(email),
            position: (position === null || position === void 0 ? void 0 : position.id) ? gen_item({ id: position.id, name: (_b = position.name) !== null && _b !== void 0 ? _b : '' }) : null,
            department: (department === null || department === void 0 ? void 0 : department.id) ? gen_item({ id: department.id, name: (_c = department.name) !== null && _c !== void 0 ? _c : '' }) : null,
        };
        return ret;
    });
}
export function getLoginInfo(key) {
    var _a;
    const raw = stroages.loginInfo.getItem('login_info');
    const info = _parseJson(raw);
    const realKey = _LoginInfoKeyMap[key];
    return (_a = info === null || info === void 0 ? void 0 : info[realKey]) !== null && _a !== void 0 ? _a : null;
}
function _parseJson(raw) {
    if (!raw) {
        return null;
    }
    try {
        return JSON.parse(raw);
    }
    catch (e) {
        return null;
    }
}
const _LoginInfoKeyMap = {
    token: 'tokenValue',
    ticket: 'ticketValue',
    userId: 'userId',
    orgId: 'orgId',
    name: 'userName',
};
export function useLoginInfo(key) {
    var _a;
    const [info] = useExpiryObjectStorage('expiry_local', 'login_info');
    if (!info) {
        return null;
    }
    const realKey = _LoginInfoKeyMap[key];
    return (_a = info[realKey]) !== null && _a !== void 0 ? _a : null;
}
export function clearLoginInfo(domainId) {
    stroages.loginInfo.removeItem('login_info');
    if (domainId === undefined) {
        const length = stroages.userInfo.length;
        for (let i = 0; i < length; i++) {
            const key = stroages.userInfo.key(i);
            if (!(key === null || key === void 0 ? void 0 : key.startsWith('user_info'))) {
                continue;
            }
            stroages.userInfo.removeItem(key);
        }
    }
    else {
        const key = getUserInfoStorageKey(domainId);
        stroages.userInfo.removeItem(key);
    }
}
export function saveLoginInfo(data, expireTime) {
    stroages.loginInfo.setItem('login_info', JSON.stringify(data), expireTime);
}
/**
 * 获取用户信息
 * @returns 用户信息
 */
export function useFullUserInfo(domainId) {
    const token = useLoginInfo('token');
    const key = getUserInfoStorageKey(domainId);
    const [value, setValue] = useExpiryObjectStorage('expiry_local', key);
    useEffect(() => {
        if (!token || value) {
            return;
        }
        const canceller = new CancelHolder;
        const qurey = domainId ? { domainId } : {};
        AuthUserInfo.call({}, qurey, canceller)
            .then(v => setValue(v.data || null))
            .catch(CancelHolder.ignoreCancelErr);
        return () => {
            canceller.cancel();
        };
    }, [token, value, domainId]);
    return value;
}
export function getUserInfoStorageKey(domainId) {
    return 'user_info_' + (domainId || '');
}
export function findUserInfo(options) {
    const { domainId } = options;
    const key = getUserInfoStorageKey(domainId);
    const raw = stroages.userInfo.getItem(key);
    return _parseJson(raw);
}
export function getUserInfo() {
    return findUserInfo({});
}
export function usePermissions() {
    const info = useFullUserInfo();
    return info === null || info === void 0 ? void 0 : info.securityPermissionList;
}
export function getPermissions() {
    const info = findUserInfo({});
    return info === null || info === void 0 ? void 0 : info.securityPermissionList;
}
export function fetchUserInfo(options) {
    return __awaiter(this, void 0, void 0, function* () {
        const { domainId, authorization, expireTime } = options;
        const key = getUserInfoStorageKey(domainId);
        const header = authorization ? { Authorization: authorization } : {};
        const qurey = domainId ? { domainId } : {};
        const { data } = yield AuthUserInfo.call(header, qurey);
        if (data) {
            stroages.userInfo.setItem(key, JSON.stringify(data), expireTime);
        }
        return data;
    });
}
/**
 * 登录成功后统一调用
 *
 * @export
 * @param {HandleLoginSuccessParams} params 接口阐述
 */
export function handleLoginSuccess(params) {
    return __awaiter(this, void 0, void 0, function* () {
        const { loginInfo, isAutoLogin, domainId } = params;
        const expireTime = (isAutoLogin ? 7 : 1) * 24 * 60 * 60 * 1000;
        clearLoginInfo(domainId);
        /*
        NOTE:
          当domainId不为空时，
          还需要额外的取获取用户的“用户中心”权限。
            -Gim
        */
        if (domainId) {
            yield fetchUserInfo({ expireTime, authorization: loginInfo.tokenValue });
        }
        yield fetchUserInfo({ domainId, expireTime, authorization: loginInfo.tokenValue });
        saveLoginInfo(loginInfo, expireTime);
    });
}
