export var Downloader;
(function (Downloader) {
    function fromUrl(opts) {
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = opts.url;
        link.setAttribute('download', opts.fileName);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    Downloader.fromUrl = fromUrl;
    function fromBlob(opts) {
        const url = window.URL.createObjectURL(opts.blob);
        fromUrl({ url, fileName: opts.fileName });
        window.URL.revokeObjectURL(url);
    }
    Downloader.fromBlob = fromBlob;
})(Downloader || (Downloader = {}));
