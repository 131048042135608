/******************************************************************
 * Copyright @ 2023 广州牛师帮文化科技有限公司. All rights reserverd.
 * @file   src\routes\Login\MobileLogin\index.tsx
 * @author Gim
 * @date   2023/01/10 15:11
 * @desc   短信登录UI
 ******************************************************************/
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as i18n from '@/i18n';
import imgSmsCode from '@assets/images/ic_yanzhengma@2x.png';
import imgPhone from '@assets/images/login_mobile@2x.png';
import { Phone } from '@nsb/common-tools/dist/phone';
import { Checkbox, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import './index.less';
export default function MobileLogin(props) {
    const { t } = i18n.useTranslation();
    const [form] = Form.useForm();
    const { logic } = props;
    const phoneOk = logic.phoneOk;
    const aok = logic.aok;
    const [captchaStatusMsg, setCaptchaStatusMsg] = useState('');
    // 点击 “获取验证码”
    const onClickSendSmsCode = () => __awaiter(this, void 0, void 0, function* () { return logic.requestSmsCode(); });
    // 点击 “登录”
    const onClickLogin = () => logic.verifySmsCode().then((ok) => {
        if (!ok)
            throw new Error(t('login.sms_code_wrong'));
        setCaptchaStatusMsg('');
        props.onAOK();
    }).catch((err) => {
        setCaptchaStatusMsg(err.message || err);
    });
    useEffect(() => {
        form.validateFields(['smsCode']);
    }, [captchaStatusMsg]);
    return (React.createElement("div", { className: 'mobile_login_view' },
        React.createElement(Form, { form: form, initialValues: { mobile: logic.mobile, smsCode: logic.smsCode } },
            React.createElement(Form.Item, { name: 'mobile', rules: [{
                        validator: (_, value) => __awaiter(this, void 0, void 0, function* () {
                            if (!Phone.isValid('86', value))
                                throw t('login.pls_enter_correct_phone_number');
                        })
                    }] },
                React.createElement(Input, { onChange: (e) => logic.setMobile(e.target.value.trim()), maxLength: 11, bordered: false, placeholder: t('login.phone_number'), prefix: React.createElement("img", { className: 'prefix', src: imgPhone }) })),
            React.createElement("div", { style: { position: 'relative', minHeight: '64px' } },
                React.createElement("div", { className: 'formItem', style: { width: 'calc(100% - 100px)' } },
                    React.createElement(Form.Item, { name: 'smsCode', rules: [{
                                validator: () => __awaiter(this, void 0, void 0, function* () {
                                    if (captchaStatusMsg)
                                        throw captchaStatusMsg.toString();
                                }),
                            }] },
                        React.createElement(Input, { onChange: (e) => logic.setSmsCode(e.target.value.trim()), maxLength: 6, bordered: false, onPressEnter: onClickLogin, placeholder: t('login.sms_code'), prefix: React.createElement("img", { className: 'prefix', src: imgSmsCode }) }))),
                React.createElement("button", { type: 'button', className: 'btn_captcha', disabled: !phoneOk || logic.isSmsSent, onClick: onClickSendSmsCode }, logic.isSmsSent ?
                    t('login.remain_time_to_get_next_sms_code').replace('%1', '' + logic.smsCountdown) :
                    t('login.get_sms_code')))),
        logic.isSmsSent && React.createElement("div", { className: 'sendedTips' }, t("login.hint_sms_code_had_sent")),
        React.createElement("div", { className: 'other' },
            React.createElement(Checkbox, { className: 'ckb_is_auto_login', checked: logic.isAutoLogin, onChange: v => logic.setIsAutoLogin(v.target.checked) }, t("login.auto_login_within_some_time"))),
        React.createElement("button", { type: 'button', className: 'btn_login', onClick: onClickLogin, disabled: !aok }, t("login.btn_login"))));
}
