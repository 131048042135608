import axios from "axios";
import qs from "qs";
import { Helper } from "./Helper";
import { PromiseInOne } from "./PromisesInOne";
export class Http {
    constructor() {
        this.defaultConfig = {
            paramsSerializer: {
                serialize: params => qs.stringify(params, { indices: false })
            }
        };
        this.pio = new PromiseInOne();
    }
    request(method, args) {
        const params = Helper.getClearParams(args.params);
        switch (method) {
            case 'post':
            case 'put':
                return axios[method](args.url, args.data, Object.assign(Object.assign(Object.assign({}, args), this.defaultConfig), { params }));
            case 'get':
            case 'options':
            case 'head':
            case 'delete':
                return axios[method](args.url, Object.assign(Object.assign(Object.assign({}, args), this.defaultConfig), { params }));
            default:
                throw new Error("mehod not supported: " + method);
        }
    }
    requestInOne(method, args) {
        return (args.cancelToken || args.signal) ?
            this.request(method, args) :
            new Promise((resolve, reject) => {
                const [pid, existed] = this.pio.intoPool(method + '_', args, resolve, reject);
                if (existed)
                    return;
                this.pio.handlePromise(pid, this.request(method, args));
            });
    }
    GET(args) { return this.requestInOne('get', args); }
    OPTIONS(args) { return this.requestInOne('options', args); }
    HEAD(args) { return this.requestInOne('head', args); }
    DELETE(args) { return this.requestInOne('delete', args); }
    POST(args) { return this.requestInOne('post', args); }
    PUT(args) { return this.requestInOne('put', args); }
}
export const http = new Http;
