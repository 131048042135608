/******************************************************************
 * Copyright @ 2023 广州牛师帮科技文化有限公司. All rights reserverd.
 * @file   src\component\RouteGuard.tsx
 * @author 朱剑豪
 * @date   2023/04/28 17:49
 * @desc   守卫路由
 ******************************************************************/
import { Paths } from '@/Paths';
import { useLoginInfo } from '@/routes/Login/logic/useLoginLogic';
import { Empty } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Route as _Route, useHistory, useLocation } from 'react-router';
export var RouteGuard;
(function (RouteGuard) {
    RouteGuard.COMPONENT_TAG = '[RouteGuard.Component]';
    const empty_style = {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    };
    function Component(props) {
        const { path } = props;
        const history = useHistory();
        const permission = Paths.Permissions[props.path];
        const hasToken = !!useLoginInfo('token') || permission === undefined;
        const hasPermission = Paths.usePermission();
        const { pathname, search, hash } = useLocation();
        useEffect(() => {
            if (!hasToken) {
                history.replace(Paths.All.LOGIN, { replace: true, state: { redirect: pathname + search + hash } });
            }
            else if (!hasPermission(path)) {
                for (const p of Paths.Fallbacks) {
                    if (hasPermission(p)) {
                        history.push(p);
                        break;
                    }
                }
            }
        }, [pathname, search, history, hasToken, hasPermission, path]);
        if (!hasToken) {
            console.log(RouteGuard.COMPONENT_TAG, 'not login');
            return React.createElement(Empty, { description: "\u672A\u767B\u5F55", style: empty_style });
        }
        else if (!hasPermission(props.path)) {
            console.log(RouteGuard.COMPONENT_TAG, 'permission denied, require:', permission);
            return React.createElement(Empty, { description: `无权限访问此页面(权限名：${permission})`, style: empty_style });
        }
        return props.children;
    }
    RouteGuard.Component = Component;
    function Route(props) {
        const { path } = props;
        const Component = Paths.Components[path];
        if (!Component) {
            return React.createElement(React.Fragment, null);
        }
        const component = useCallback(() => {
            return (React.createElement(RouteGuard.Component, { path: path },
                React.createElement(Component, null)));
        }, [path]);
        return useMemo(() => React.createElement(_Route, { path: path, component: component }), [component]);
    }
    RouteGuard.Route = Route;
})(RouteGuard || (RouteGuard = {}));
