export default {
    SAVE_DEPARTMENT_LIST: 'SAVE_DEPARTMENT_LIST',
    WAIT_DEPARTMENT_LIST: 'WAIT_DEPARTMENT_LIST',
    SAVE_POSITION_LIST: 'SAVE_POSITION_LIST',
    SAVE_ROLE_LIST: 'SAVE_ROLE_LIST',
    SAVE_PRODUCT_LIST: 'SAVE_PRODUCT_LIST',
    SAVE_TEACHER_TYPE_LIST: 'SAVE_TEACHER_TYPE_LIST',
    SAVE_EMPLOYEE_LIST: 'SAVE_EMPLOYEE_LIST',
    SAVE_TEACHER_LEVEL_LIST: 'SAVE_TEACHER_LEVEL_LIST',
    SAVE_TEACHER_TOP_COURSE_LIST: 'SAVE_TEACHER_TOP_COURSE_LIST',
    SAVE_GRADE_SEGMENT_LIST: 'SAVE_GRADE_SEGMENT_LIST',
    SAVE_GRADE_LIST: 'SAVE_GRADE_LIST',
    SAVE_ALL_TEACHER_TYPE_LIST: "SAVE_ALL_TEACHER_TYPE_LIST",
    SAVE_TEACHER_EXPERIENCE_LIST: 'SAVE_TEACHER_EXPERIENCE_LIST',
    SAVE_ONLINE_SUBSCHOOL_LIST: 'SAVE_ONLINE_SUBSCHOOL_LIST',
    SAVE_OFFLINE_SUBSCHOOL_LIST: 'SAVE_OFFLINE_SUBSCHOOL_LIST',
    SAVE_IS_JUMP_FROM_TEACHER_LIST: 'SAVE_IS_JUMP_FROM_TEACHER_LIST',
    QUALIFICATION_STATUS: 'QUALIFICATION_STATUS',
    SAVE_TOPCOURSE_LIST: 'SAVE_TOPCOURSE_LIST',
    INTRODUCE_STATUS: 'INTRODUCE_STATUS',
    IS_REGISTRATION: 'IS_REGISTRATION',
    SAVE_USER_INFO: 'SAVE_USER_INFO',
    SAVE_MSG_TYPE: 'SAVE_MSG_TYPE',
    SAVE_OSS_PARAMS: 'SAVE_OSS_PARAMS',
    SAVE_MAIN_TEACHER_SUBSCHOOL_LIST: 'SAVE_MAIN_TEACHER_SUBSCHOOL_LIST'
};
