var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as i18n from '@/i18n';
import imgSmsCode from '@assets/images/ic_yanzhengma@2x.png';
import imgPhone from '@assets/images/login_mobile@2x.png';
import imgUserName from '@assets/images/Man@2x.png';
import imgPassword from '@assets/images/Med_kit@2x.png';
import { Phone } from '@nsb/common-tools/dist/phone';
import { Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import './index.less';
export default function FindPwd(props) {
    const { t } = i18n.useTranslation();
    const { logic } = props;
    const [pwdErrMsg, setPwdErrMsg] = useState('');
    const [captchaStatusMsg, setCaptchaStatusMsg] = useState('');
    const [form] = Form.useForm();
    const { password1, password2, phoneOk, aok } = logic;
    useEffect(() => {
        if (password1 && password2)
            form.validateFields(['password2']);
    }, [password1, password2]);
    useEffect(() => {
        setPwdErrMsg('');
    }, [password1]);
    // 点击“获取验证码”
    const onClickSendSmsCode = () => logic.requestSmsCode();
    const onClickConfirm = () => logic.verifySmsCode()
        .then((ok) => {
        if (!ok)
            throw new Error(t('login.sms_code_wrong'));
        setCaptchaStatusMsg('');
        props.onConfirm();
    }).catch((err) => {
        setCaptchaStatusMsg(err.message || err);
    });
    /** 监听captchaStatus检验表单 */
    useEffect(() => {
        form.validateFields(['smsCode']);
    }, [captchaStatusMsg]);
    return (React.createElement("div", { className: 'find_pwd_view' },
        React.createElement("h1", { className: 'header' }, t('login.reset_password')),
        React.createElement("div", { className: 'main' },
            React.createElement(Form, { form: form },
                React.createElement(Form.Item, { name: 'userName' },
                    React.createElement(Input, { prefix: React.createElement("img", { className: 'prefix', src: imgUserName }), placeholder: t("login.username"), bordered: false, autoComplete: 'username', onChange: v => logic.setUserName(v.target.value.trim()) })),
                React.createElement(Form.Item, { name: 'mobile', rules: [{
                            validator: (_, value) => __awaiter(this, void 0, void 0, function* () {
                                if (!Phone.isValid('86', value))
                                    throw t('login.pls_enter_correct_phone_number');
                            })
                        }] },
                    React.createElement(Input, { onChange: (e) => logic.setMobile(e.target.value.trim()), maxLength: 11, bordered: false, placeholder: t('login.phone_number'), prefix: React.createElement("img", { className: 'prefix', src: imgPhone }) })),
                React.createElement("div", { style: { position: 'relative', minHeight: '64px' } },
                    React.createElement("div", { className: 'formItem', style: { width: 'calc(100% - 100px)' } },
                        React.createElement(Form.Item, { name: 'smsCode', rules: [{
                                    validator: () => __awaiter(this, void 0, void 0, function* () {
                                        if (captchaStatusMsg)
                                            throw captchaStatusMsg.toString();
                                    }),
                                }] },
                            React.createElement(Input, { onChange: (e) => logic.setSmsCode(e.target.value.trim()), maxLength: 6, bordered: false, placeholder: t('login.sms_code'), prefix: React.createElement("img", { className: 'prefix', src: imgSmsCode }) }))),
                    React.createElement("button", { type: 'button', className: 'btn_captcha', disabled: !phoneOk || logic.isSmsSent, onClick: onClickSendSmsCode }, logic.isSmsSent ?
                        t('login.remain_time_to_get_next_sms_code').replace('%1', '' + logic.smsCountdown) :
                        t('login.get_sms_code'))),
                React.createElement(Form.Item, { name: 'password', rules: [{
                            validator: () => __awaiter(this, void 0, void 0, function* () {
                                if (pwdErrMsg)
                                    throw pwdErrMsg;
                            })
                        }] },
                    React.createElement(Input.Password, { onChange: e => logic.setPassword1(e.target.value.trim()), bordered: false, placeholder: t('login.placeholder_pls_enter_new_pwd'), prefix: React.createElement("img", { className: 'prefix', src: imgPassword }) })),
                React.createElement(Form.Item, { name: 'password2', rules: [{
                            validator: (_, value) => __awaiter(this, void 0, void 0, function* () {
                                if (password1 !== password2)
                                    throw t('login.pwd_should_equal_to_pwd_1');
                            })
                        }] },
                    React.createElement(Input.Password, { onChange: e => logic.setPassword2(e.target.value.trim()), bordered: false, placeholder: t('login.placeholder_pls_enter_new_pwd_again'), prefix: React.createElement("img", { className: 'prefix', src: imgPassword }) }))),
            logic.isSmsSent &&
                React.createElement("div", { className: 'sendedTips' }, t('login.hint_sms_code_had_sent')),
            React.createElement("button", { type: 'button', disabled: !aok, className: 'btn_confirm', onClick: onClickConfirm }, t('login.btn_confirm')),
            React.createElement("button", { type: 'button', className: 'btn_back', onClick: props.onClickBack }, t('login.btn_back')))));
}
