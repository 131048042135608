import SYSTEM_ACTION_TYPES from "@constant/action-types/system";
const initialState = {
    scaleRate: 1, // 全局scaleRate
};
/**
 * 储存全局参数
 */
export default function guidance(state = initialState, { type, payload }) {
    switch (type) {
        case SYSTEM_ACTION_TYPES.SET_SYSTEM_SCALE_RATE:
            return Object.assign(Object.assign({}, state), { scaleRate: payload });
    }
    return state;
}
