import { ConfigProvider } from "antd";
import zhCN from 'antd/es/locale/zh_CN';
import React from "react";
import ReactDOM from "react-dom";
import { Redirect, BrowserRouter as Router, Switch } from "react-router-dom";
import { AuthChecker } from "./AuthChecker";
import { Paths } from './Paths';
import { StateProvider } from "./components/StateProvider";
import './inits';
const App = () => {
    return (React.createElement(StateProvider, null,
        React.createElement(ConfigProvider, { locale: zhCN },
            React.createElement(Router, null,
                React.createElement(AuthChecker, null),
                React.createElement(Switch, null,
                    React.createElement(Redirect, { exact: true, to: Paths.All.TEACHER_LIST, from: Paths.All.ROOT }),
                    React.createElement(Redirect, { exact: true, to: Paths.All.TEACHER_LIST, from: Paths.All.MAIN }),
                    React.createElement(Paths.Routes, { path: Paths.All.ROOT }))))));
};
ReactDOM.render(React.createElement(App, null), document.getElementById("root"));
