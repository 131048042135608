import GUIDANCE_ACTION_TYPES from "@constant/action-types/guidance";
const initialState = {
    loading: false, // 导学案全局loading
};
/**
 * 储存全局参数
 */
export default function guidance(state = initialState, { type, payload }) {
    switch (type) {
        case GUIDANCE_ACTION_TYPES.SET_GUIDANCE_LOADING:
            return Object.assign(Object.assign({}, state), { loading: payload });
    }
    return state;
}
