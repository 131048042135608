import { getPermissions, usePermissions } from "@/routes/Login";
import { useCallback } from "react";
export var UserPermission;
(function (UserPermission) {
    ;
    function use() {
        const granteds = usePermissions();
        return useCallback((permissions) => {
            const requires = permissions === null || permissions === void 0 ? void 0 : permissions.split(',').filter(v => v);
            const granteds = UserPermission.get();
            if (!(requires === null || requires === void 0 ? void 0 : requires.length)) {
                return true;
            }
            if (!(granteds === null || granteds === void 0 ? void 0 : granteds.length)) {
                return false;
            }
            for (let i = 0; i < requires.length; ++i) {
                const idx = granteds.findIndex(v => v.name === requires[i]);
                if (idx < 0) {
                    return false;
                }
            }
            return true;
        }, [granteds]);
    }
    UserPermission.use = use;
    function get() {
        return getPermissions();
    }
    UserPermission.get = get;
})(UserPermission || (UserPermission = {}));
