var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import md5 from "blueimp-md5";
export class PromiseInOne {
    constructor() {
        this.promisePool = {};
    }
    genPromiseId(type, args) {
        return type + md5(JSON.stringify(args));
    }
    intoPool(type, args, resolve, reject) {
        var _a;
        const promiseId = this.genPromiseId(type, args);
        const existed = !!this.promisePool[promiseId];
        if (!existed)
            this.promisePool[promiseId] = [{ resolve, reject }];
        else
            (_a = this.promisePool[promiseId]) === null || _a === void 0 ? void 0 : _a.push({ resolve, reject });
        return [promiseId, existed];
    }
    rejectPromise(promiseId, e) {
        var _a;
        (_a = this.promisePool[promiseId]) === null || _a === void 0 ? void 0 : _a.forEach(v => v.reject(e));
        delete this.promisePool[promiseId];
    }
    resolvePromise(promiseId, d) {
        var _a;
        (_a = this.promisePool[promiseId]) === null || _a === void 0 ? void 0 : _a.forEach(v => v.resolve(d));
        delete this.promisePool[promiseId];
    }
    handlePromise(promiseId, promise) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const reply = yield promise;
                return this.resolvePromise(promiseId, reply);
            }
            catch (err) {
                return this.rejectPromise(promiseId, err);
            }
        });
    }
}
