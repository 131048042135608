/******************************************************************
 * Copyright @ 2023 广州牛师帮文化科技有限公司. All rights reserverd.
 * @file   src\routes\Login\logic\useSmsLoginLogic.tsx
 * @author Gim
 * @date   2023/01/11 11:04
 * @desc   短信登录逻辑封装
 ******************************************************************/
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AuthLoginSms } from '@/api/AuthLoginSms';
import { AuthLoginVfcodeSend } from '@/api/AuthLoginVfcodeSend'; // TORNA-API-VISITOR
import { PublicGetCaptchaInfo } from '@/api/PublicGetCaptchaInfo'; // TORNA-API-VISITOR
import { PublicVerifySmsCode } from '@/api/PublicVerifySmsCode'; // TORNA-API-VISITOR
import { Countdown } from '@nsb/common-tools/dist/hooks/useCountdown';
import { Phone } from '@nsb/common-tools/dist/phone';
import getTencentCaptcha from 'dynamic-tencent-captcha';
import { useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { handleLoginSuccess } from './useLoginLogic';
import qs from 'qs';
export function useSmsLoginLogic(options) {
    const history = useHistory();
    const redirect = options === null || options === void 0 ? void 0 : options.redirect;
    const { search } = useLocation();
    const { domainId } = useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }), [search]);
    const [mobile, setMobile] = useState('');
    const [smsCode, setSmsCode] = useState('');
    const { remaining: smsCountdown, isStarted: isSmsSent, setIsStarted } = Countdown.use({ duration: 60, started: false });
    const [isAutoLogin, setIsAutoLogin] = useState(false); // 是否七天自动登录
    const phoneOk = Phone.isValid('86', mobile);
    const aok = smsCode.length === 6 && phoneOk;
    const requestSmsCode = () => __awaiter(this, void 0, void 0, function* () {
        var _a;
        const data = yield PublicGetCaptchaInfo.call();
        const appId = '' + ((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.appid);
        const { ticket, randstr } = yield getTencentCaptcha(appId);
        yield AuthLoginVfcodeSend.call({ captchaAppId: appId, captchaTicket: ticket, captchaRandstr: randstr }, { areaCode: '86', mobile, type: 'LOGIN' });
        setIsStarted(true);
    });
    const verifySmsCode = () => PublicVerifySmsCode.call({
        mobile: mobile,
        smsCode: smsCode,
    });
    const login = (org) => __awaiter(this, void 0, void 0, function* () {
        const params = { orgId: org.id, mobile, smsCode };
        const { data } = yield AuthLoginSms.call(params);
        if (!data) {
            throw new Error(`data got ${data}`);
        }
        yield handleLoginSuccess({
            loginInfo: data,
            isAutoLogin,
            domainId: Number(domainId)
        });
        if (redirect && typeof redirect === 'string')
            history.replace(redirect);
    });
    return {
        mobile, setMobile,
        smsCode, setSmsCode,
        isAutoLogin, setIsAutoLogin,
        smsCountdown, isSmsSent, phoneOk, aok,
        requestSmsCode,
        verifySmsCode,
        login
    };
}
