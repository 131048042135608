import guidanceReducer from "./guidance";
import systemReducer from "./system";
import controlReducer from "./control";
import appReducer from "./app";
import commonReducer from './commonReducer';
const reducerMap = {
    guidance: guidanceReducer,
    system: systemReducer,
    control: controlReducer,
    app: appReducer,
    common: commonReducer
};
function rootReducer(state = undefined, action = {}) {
    const nextState = {};
    for (let key in reducerMap) {
        nextState[key] = reducerMap[key](state === undefined ? state : state[key], action);
    }
    return nextState;
}
export default rootReducer;
