import { clearLoginInfo } from "@/routes/Login";
export class KnownError extends Error {
    constructor(code, msg) {
        super(`[${code}]${msg}`);
        this.code = code;
    }
}
export function checkReply(reply) {
    if (!reply)
        throw new Error(`服务异常，返回数据为${JSON.stringify(reply)}`);
    if (reply.code == 401)
        clearLoginInfo();
    if (reply.code != 0 && reply.code != 200)
        throw new KnownError('' + reply.code, reply.msg || '服务异常');
    return reply;
}
