import TYPES from '@/constant/action-types/commonType';
const initialState = {
    DEPARTMENT_LIST: [],
    DEPARTMENT_LIST_LOADING: false,
    POSITION_LIST: [],
    ROLE_LIST: [],
    PRODUCT_LIST: [],
    TEACHER_TYPE_LIST: [],
    EMPLOYEE_LIST: [],
    TEACHER_LEVEL_LIST: [],
    TEACHER_TOP_COURSE_LIST: [],
    GRADE_SEGMENT_LIST: [],
    GRADE_LIST: [],
    ONLINE_SUBSCHOOL_LIST: [],
    OFFLINE_SUBSCHOOL_LIST: [],
    TEACHER_EXPERIENCE_LIST: [],
    MAIN_SUBJECT_LIST: [],
    OPTIONAL_SUBJECT_LIST: [],
    STUDY_SCOPE_LIST: [],
    IS_JUMP_FROM_TEACHER_LIST: false,
    TOPCOURSE_LIST: [],
    QUALIFICATION_STATUS_LIST: [],
    INTRODUCE_STATUS_LIST: [],
    IS_REGISTRATION_LIST: [],
    USER_INFO: {},
    MSG_TYPE: [],
    OSS_PARAM: undefined,
    MAIN_TEACHER_SUBSCHOOL_LIST: [], // 主任教校区列表
};
export default function query(state = initialState, { type, payload }) {
    switch (type) {
        case TYPES.SAVE_DEPARTMENT_LIST:
            return Object.assign(Object.assign({}, state), { DEPARTMENT_LIST_LOADING: false, DEPARTMENT_LIST: payload });
        case TYPES.WAIT_DEPARTMENT_LIST: return Object.assign(Object.assign({}, state), { DEPARTMENT_LIST_LOADING: true });
        case TYPES.SAVE_POSITION_LIST:
            return Object.assign(Object.assign({}, state), { POSITION_LIST: payload });
        case TYPES.SAVE_ROLE_LIST:
            return Object.assign(Object.assign({}, state), { ROLE_LIST: payload });
        case TYPES.SAVE_PRODUCT_LIST:
            return Object.assign(Object.assign({}, state), { PRODUCT_LIST: payload });
        case TYPES.SAVE_TEACHER_TYPE_LIST:
            return Object.assign(Object.assign({}, state), { TEACHER_TYPE_LIST: payload });
        case TYPES.SAVE_EMPLOYEE_LIST:
            return Object.assign(Object.assign({}, state), { EMPLOYEE_LIST: payload });
        case TYPES.SAVE_TEACHER_LEVEL_LIST:
            return Object.assign(Object.assign({}, state), { TEACHER_LEVEL_LIST: payload });
        case TYPES.SAVE_TEACHER_TOP_COURSE_LIST:
            return Object.assign(Object.assign({}, state), { TEACHER_TOP_COURSE_LIST: payload });
        case TYPES.SAVE_GRADE_SEGMENT_LIST:
            return Object.assign(Object.assign({}, state), { GRADE_SEGMENT_LIST: payload });
        case TYPES.QUALIFICATION_STATUS:
            return Object.assign(Object.assign({}, state), { QUALIFICATION_STATUS_LIST: payload });
        case TYPES.SAVE_GRADE_LIST:
            return Object.assign(Object.assign({}, state), { GRADE_LIST: payload });
        case TYPES.SAVE_ALL_TEACHER_TYPE_LIST:
            return Object.assign(Object.assign({}, state), { TEACHER_TYPE_LIST: payload });
        case TYPES.SAVE_TEACHER_EXPERIENCE_LIST:
            return Object.assign(Object.assign({}, state), { TEACHER_EXPERIENCE_LIST: payload });
        case TYPES.SAVE_ONLINE_SUBSCHOOL_LIST:
            return Object.assign(Object.assign({}, state), { ONLINE_SUBSCHOOL_LIST: payload });
        case TYPES.SAVE_OFFLINE_SUBSCHOOL_LIST:
            return Object.assign(Object.assign({}, state), { OFFLINE_SUBSCHOOL_LIST: payload });
        case TYPES.SAVE_TOPCOURSE_LIST:
            return Object.assign(Object.assign({}, state), { TOPCOURSE_LIST: payload });
        case TYPES.INTRODUCE_STATUS:
            return Object.assign(Object.assign({}, state), { INTRODUCE_STATUS_LIST: payload });
        case TYPES.IS_REGISTRATION:
            return Object.assign(Object.assign({}, state), { IS_REGISTRATION_LIST: payload });
        case TYPES.SAVE_IS_JUMP_FROM_TEACHER_LIST:
            return Object.assign(Object.assign({}, state), { IS_JUMP_FROM_TEACHER_LIST: payload });
        case TYPES.SAVE_USER_INFO:
            return Object.assign(Object.assign({}, state), { USER_INFO: payload });
        case TYPES.SAVE_MSG_TYPE:
            return Object.assign(Object.assign({}, state), { MSG_TYPE: payload });
        case TYPES.SAVE_OSS_PARAMS:
            return Object.assign(Object.assign({}, state), { OSS_PARAM: payload });
        case TYPES.SAVE_MAIN_TEACHER_SUBSCHOOL_LIST:
            return Object.assign(Object.assign({}, state), { MAIN_TEACHER_SUBSCHOOL_LIST: payload });
        default:
            return state;
    }
}
