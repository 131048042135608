import { AxiosError } from 'axios';
import { Http } from './Http';
export const friendlyError = (e) => {
    if (e instanceof AxiosError) {
        switch (e.code) {
            case 'ERR_NETWORK': // FIXME: 需要一个合理的国际化的方法。-Gim
                e.message = '网络异常';
                break;
        }
    }
    throw e;
};
export class ApiHttp extends Http {
    GET(args) {
        return super.GET(args).then(v => v.data).catch(friendlyError);
    }
    POST(args) {
        return super.POST(args).then(v => v.data).catch(friendlyError);
    }
    OPTIONS(args) {
        return super.GET(args).then(v => v.data).catch(friendlyError);
    }
    HEAD(args) {
        return super.HEAD(args).then(v => v.data).catch(friendlyError);
    }
    PUT(args) {
        return super.PUT(args).then(v => v.data).catch(friendlyError);
    }
    DELETE(args) {
        return super.DELETE(args).then(v => v.data).catch(friendlyError);
    }
}
export const apiHttp = new ApiHttp();
