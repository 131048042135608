/******************************************************************
 * Copyright @ 2023 广州牛师帮文化科技有限公司. All rights reserverd.
 * @file   src\routes\Login\logic\useOrgList.tsx
 * @author Gim
 * @date   2023/01/11 12:38
 * @desc   获取（用户名下/手机）所属机构
 ******************************************************************/
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AuthLoginFindOrgIdByEmployeeUserName as ApiListOrgByName } from '@/api/AuthLoginFindOrgIdByEmployeeUserName';
import { AuthLoginFindOrgListByEmployeeMobile as ApiListOrgByPhone } from '@/api/edited/AuthLoginFindOrgListByEmployeeMobile';
import { AuthLoginFindOrgListByEmployeeUnionId_post as ApiListOrgByWx } from "../../../api/AuthLoginFindOrgListByEmployeeUnionId_post"; // TAV
import { CancelHolder } from '@/network';
import { message } from 'antd';
import { useEffect, useState } from 'react';
import { ViewType } from '../LoginView';
export function getOrgList(options, canceller) {
    return __awaiter(this, void 0, void 0, function* () {
        const { userName, phone, login_way, loginCode } = options;
        switch (login_way) {
            case ViewType.PasswordLogin:
                if (!userName)
                    throw new Error('userName cant be ' + JSON.stringify(userName));
                return ApiListOrgByName.call({ "userName": userName }, canceller).then(v => v.data);
            case ViewType.MobileLogin:
            case ViewType.ResetPassword:
                if (!phone)
                    throw new Error('phone cant be ' + JSON.stringify(phone));
                return ApiListOrgByPhone.call({ "mobile": phone }, canceller).then(v => v.data);
            case ViewType.WechatLogin:
                if (!loginCode)
                    throw new Error('loginCode cant be ' + JSON.stringify(loginCode));
                return ApiListOrgByWx.call({ 'loginCode': loginCode }, canceller).then(v => v.data);
            case void 0:
                throw new Error(`can not login without login_way`);
            default:
                throw new Error(`login way not supported, ${ViewType[login_way]}`);
        }
    });
}
export function useOrgList(options) {
    const [loading, setIsLoading] = useState(false);
    const [orgList, setOrgList] = useState([]);
    const [error, setError] = useState();
    const { userName, phone, trigger, login_way } = options;
    useEffect(() => {
        if (!trigger || !login_way) {
            setIsLoading(false);
            setOrgList([]);
            setError(void 0);
            return;
        }
        const canceller = new CancelHolder;
        setIsLoading(true);
        setError(void 0);
        getOrgList(options, canceller).then(data => {
            setIsLoading(false);
            const orgList = data === null || data === void 0 ? void 0 : data.filter(v => v.id && v.name);
            setOrgList(orgList || []);
        }).catch((e) => {
            setIsLoading(false);
            return CancelHolder.ignoreCancelErr(e);
        }).catch((e) => {
            message.warn(e);
            setError(e);
        });
        return () => {
            canceller.cancel();
        };
    }, [trigger, userName, phone, login_way]);
    return { orgList, loading, error, cleanError: () => setError(undefined) };
}
