/******************************************************************
 * Copyright @ 2023 广州牛师帮文化科技有限公司. All rights reserverd.
 * @file   src\routes\Login\logic\useResetPwdLogic.tsx
 * @author Gim
 * @date   2023/01/11 11:05
 * @desc   密码重置的逻辑封装
 ******************************************************************/
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AuthLoginVfcodeSend } from '@/api/AuthLoginVfcodeSend'; // TORNA-API-VISITOR
import { PublicForgetPasswordReset } from '@/api/PublicForgetPasswordReset'; // TORNA-API-VISITOR
import { PublicGetCaptchaInfo } from '@/api/PublicGetCaptchaInfo'; // TORNA-API-VISITOR
import { PublicVerifySmsCode } from '@/api/PublicVerifySmsCode'; // TORNA-API-VISITOR
import { Countdown } from '@nsb/common-tools/dist/hooks/useCountdown';
import { Phone } from '@nsb/common-tools/dist/phone';
import getTencentCaptcha from 'dynamic-tencent-captcha';
import { useState } from 'react';
export function useResetPwdLogic() {
    const [userName, setUserName] = useState('');
    const [mobile, setMobile] = useState('');
    const [smsCode, setSmsCode] = useState('');
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const { remaining: smsCountdown, isStarted: isSmsSent, setIsStarted } = Countdown.use({ duration: 60, started: false });
    const phoneOk = Phone.isValid('86', mobile);
    const aok = (smsCode === null || smsCode === void 0 ? void 0 : smsCode.length) === 6 &&
        phoneOk &&
        password1.length >= 4 &&
        password1.length <= 32 &&
        password1 === password2;
    const requestSmsCode = () => __awaiter(this, void 0, void 0, function* () {
        var _a;
        const resp = yield PublicGetCaptchaInfo.call();
        const appId = '' + ((_a = resp === null || resp === void 0 ? void 0 : resp.data) === null || _a === void 0 ? void 0 : _a.appid);
        const { ticket, randstr } = yield getTencentCaptcha(appId);
        yield AuthLoginVfcodeSend.call({ captchaAppId: appId, captchaTicket: ticket, captchaRandstr: randstr }, { areaCode: '86', mobile, type: 'REST_PWD' });
        setIsStarted(true);
    });
    const verifySmsCode = () => PublicVerifySmsCode.call({
        mobile: mobile,
        smsCode: smsCode,
    });
    const confirm = (org) => PublicForgetPasswordReset.call({
        orgId: org.id,
        userName,
        newPassword: password1,
        mobile,
        vfCode: smsCode
    });
    return {
        userName, setUserName,
        mobile, setMobile,
        smsCode, setSmsCode,
        password1, setPassword1,
        password2, setPassword2,
        smsCountdown, isSmsSent, phoneOk, aok,
        requestSmsCode,
        verifySmsCode,
        confirm
    };
}
