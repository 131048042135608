//// Generated by Torna Api Stealer. DO NOT EDIT IT DIRECTLY!
//// @name         短信登录-获取机构
//// @author       
//// @see          https://api-doc.niushibang.net/#/view/e24v9eXL
//// 
//// @docId        e24v9eXL
//// @docUrl       https://api-doc.niushibang.net
//// @baseUrl      
//// @apiUrl       /auth/login/findOrgListByEmployeeMobile
//// @envs         
////               [dev] https://b-api-dev.niushibang.net/userCenter
//// @httpMethod   POST
//// @description  
//// @createTime   2023-01-03 14:13:00
//// @lastModified 2023-01-11 14:59:43 
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { apiHttp } from '@/network/ApiHttp';
import { checkReply } from '../checkReply';
export var AuthLoginFindOrgListByEmployeeMobile;
(function (AuthLoginFindOrgListByEmployeeMobile) {
    function call(params, canceller) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = __SERVER__ + "/auth/login/findOrgListByEmployeeMobile";
            const reply = yield apiHttp["POST"](Object.assign({ url,
                params }, canceller === null || canceller === void 0 ? void 0 : canceller.signals()));
            return checkReply(reply);
        });
    }
    AuthLoginFindOrgListByEmployeeMobile.call = call;
})(AuthLoginFindOrgListByEmployeeMobile || (AuthLoginFindOrgListByEmployeeMobile = {}));
