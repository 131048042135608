import { SET_APP_GRADELIST, SET_APP_TOPCOURSELIST, SET_APP_DEPARMENTLIST } from "@constant/action-types/app";
const initialState = {
    gradeList: [],
    topCourseList: [],
    deparmentList: [],
};
export default function app(state = initialState, { type, payload }) {
    switch (type) {
        case SET_APP_GRADELIST:
            return Object.assign(Object.assign({}, state), { gradeList: payload });
        case SET_APP_TOPCOURSELIST:
            return Object.assign(Object.assign({}, state), { topCourseList: payload });
        case SET_APP_DEPARMENTLIST:
            return Object.assign(Object.assign({}, state), { deparmentList: payload });
    }
    return state;
}
