import { getLoginInfo } from '@/routes/Login';
import { LiveStorage } from '@nsb/common-tools/dist/LiveStorage';
import 'antd-mobile/dist/antd-mobile.css';
import 'antd/dist/antd.css';
import axios from 'axios';
import moment from "moment";
import 'moment/locale/zh-cn';
import './i18n';
import "./styles/index.less";
import "./styles/theme.less";
import 'lib-flexible';
import qs from "qs";
import { QUERY_DEBUG } from "./constant/query";
moment.locale('zh');
axios.interceptors.request.use((config) => {
    const headers = config.headers;
    if (!headers.has('Authorization')) {
        const token = getLoginInfo('token');
        headers.set('Authorization', token);
    }
    return config;
}, (error) => {
    console.error(`request请求失败${typeof error == 'object' ? JSON.stringify(error) : error}`);
    return Promise.reject(error);
});
axios.interceptors.response.use((response) => {
    var _a;
    if (!response)
        return Promise.reject(new Error(`[axios] response got ${response}`));
    const { config, status, statusText, data } = response;
    if (((_a = config === null || config === void 0 ? void 0 : config.url) === null || _a === void 0 ? void 0 : _a.endsWith(".aliyuncs.com")) && (config === null || config === void 0 ? void 0 : config.method) === 'post') {
        response.data = {
            success: true,
            code: status || 200,
            msg: statusText,
            data: data
        };
    }
    return response;
}, (error) => {
    if (axios.isCancel(error)) {
        console.debug('请求被取消', error);
    }
    else {
        console.error("响应出错：", error);
    }
    return Promise.reject(error);
});
window.URL = window.URL || window.webkitURL;
const versionTag = '1625736479839';
const storage = LiveStorage.get('local');
if (versionTag !== storage.getItem('versionTag')) {
    storage.clear();
    storage.setItem('versionTag', versionTag);
}
// debug模式
const { [QUERY_DEBUG]: debug } = qs.parse(location.search, { ignoreQueryPrefix: true });
if (debug === "true") {
    console.info("debug mode");
    import(/* webpackChunkName: "vconsole" */ "vconsole").then(vconsole => {
        new vconsole.default();
    });
}
