var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AxiosHeaders } from "axios";
export var ContentDisposition;
(function (ContentDisposition) {
    function parse(headersOrRawStr) {
        return __awaiter(this, void 0, void 0, function* () {
            const rawStr = typeof headersOrRawStr === 'string' ?
                headersOrRawStr :
                headersOrRawStr instanceof AxiosHeaders ?
                    headersOrRawStr.get('content-disposition') :
                    headersOrRawStr['content-disposition'];
            if (typeof rawStr !== 'string') {
                throw new Error('无法获取content-disposition, 请确定返回数据头中已正确的设置Access-Control-Expose-Headers');
            }
            const ret = {
                type: '',
                parameters: {}
            };
            if (typeof rawStr === 'string') {
                const parts = rawStr.split(';')
                    .map(v => v.trim())
                    .filter(v => v);
                ret.type = parts[0];
                const len = parts.length;
                for (let i = 1; i < len; ++i) {
                    const result = parts[i].match(/(.*?)=(.*?)$/);
                    if (!result) {
                        continue;
                    }
                    if (result[1] === 'filename*') {
                        const valueResult = result[2].match(/(.*)'(.*)'(.*)/);
                        if (!valueResult) {
                            continue;
                        }
                        ret.parameters['charset'] = valueResult[1];
                        ret.parameters['lang'] = valueResult[2];
                        ret.parameters['fileName'] = decodeURI(valueResult[3]);
                        continue;
                    }
                    ret.parameters[result[1]] = result[2];
                }
            }
            return ret;
        });
    }
    ContentDisposition.parse = parse;
})(ContentDisposition || (ContentDisposition = {}));
