var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/******************************************************************
 * Copyright @ 2023 广州牛师帮文化科技有限公司. All rights reserverd.
 * @file   src\routes\Login\LoginView.tsx
 * @author Gim
 * @date   2023/01/11 15:05
 * @desc   登录VIEW
 *          账号密码登录
 *          手机短信登录
 *          密码重置
 *          微信登录（TODO）
 ******************************************************************/
import { PublicGetWxLoginUrl } from '@/api/PublicGetWxLoginUrl'; // TORNA-API-VISITOR
import { CancelHolder } from '@/network';
import { CloseOutlined } from '@ant-design/icons';
import { message } from 'antd';
import classNames from 'classnames';
import qs from 'qs';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import FindPwd from './FindPwd';
import './index.less';
import { useOrgList, usePwdLoginLogic, useResetPwdLogic, useSmsLoginLogic } from './logic';
import MobileLogin from './MobileLogin';
import PwdLogin from './PwdLogin';
import SelectOrg from './SelectOrg';
export var ViewType;
(function (ViewType) {
    ViewType[ViewType["PasswordLogin"] = 1] = "PasswordLogin";
    ViewType[ViewType["MobileLogin"] = 2] = "MobileLogin";
    ViewType[ViewType["WechatLogin"] = 3] = "WechatLogin";
    ViewType[ViewType["ResetPassword"] = 4] = "ResetPassword";
    ViewType[ViewType["SelectOrganiztion"] = 5] = "SelectOrganiztion";
})(ViewType || (ViewType = {}));
function useStack(init) {
    const [items, setItems] = useState(init);
    return {
        top: items[items.length - 1],
        prev: items[items.length - 2],
        bottom: items[0],
        pop: () => {
            const n = [...items];
            n.pop();
            setItems(n);
        },
        push: (v) => {
            setItems(items.concat(v));
        },
        reset: (v) => {
            setItems(v);
        },
        replace: (v) => {
            const n = [...items];
            n[n.length - 1] = v;
            setItems(n);
        }
    };
}
export function LoginView(props) {
    const { t } = useTranslation();
    const viewStack = useStack([ViewType.PasswordLogin]);
    // 账号密码登录
    const pwdLoginLogic = usePwdLoginLogic({ redirect: props.redirect });
    // 手机短信登录
    const smsLoginLogic = useSmsLoginLogic({ redirect: props.redirect });
    // 重置密码
    const resetPwdLogic = useResetPwdLogic();
    const { location } = useHistory();
    const search_obj = useMemo(() => qs.parse(location.search, { ignoreQueryPrefix: true }), []);
    const { loginCode } = search_obj;
    useEffect(() => {
        if (!search_obj.loginCode)
            return;
        const next_searh_obj = Object.assign({}, search_obj);
        delete next_searh_obj.loginCode;
        const { protocol, host, pathname } = window.location;
        const next_url = protocol + '//' + host + pathname + qs.stringify(next_searh_obj, { addQueryPrefix: true });
        history.replaceState(history.state, '', next_url);
    }, [search_obj]);
    const [wxLoginUrl, setWxLoginUrl] = useState(''); // 微信登录——授权地址
    useEffect(() => {
        if (typeof loginCode !== 'string')
            return;
        set_login_way(ViewType.WechatLogin);
    }, [location]);
    const [login_way, set_login_way] = useState(); // 登录方式
    const { orgList, loading: isOrgListLoading, error: orgListError } = useOrgList({
        loginCode: typeof loginCode === 'string' ? loginCode : void 0,
        userName: pwdLoginLogic.userName || resetPwdLogic.userName,
        phone: smsLoginLogic.mobile || resetPwdLogic.mobile,
        login_way: login_way,
        trigger: login_way !== void 0,
    });
    const onOrgSelected = (org) => __awaiter(this, void 0, void 0, function* () {
        try {
            if (login_way === ViewType.PasswordLogin) {
                yield pwdLoginLogic.login(org);
            }
            else if (login_way === ViewType.MobileLogin) {
                yield smsLoginLogic.login(org);
            }
            else if (login_way === ViewType.ResetPassword) {
                yield resetPwdLogic.confirm(org);
                message.success("修改成功");
            }
        }
        catch (e) {
            message.warn((e === null || e === void 0 ? void 0 : e.message) || e);
        }
        viewStack.reset([viewStack.bottom]);
    });
    useEffect(() => {
        if (!login_way)
            return;
        if (orgList.length === 1) {
            onOrgSelected(orgList[0]);
        }
        else if (orgList.length) {
            viewStack.push(ViewType.SelectOrganiztion);
        }
    }, [orgList, login_way]);
    useEffect(() => {
        if (wxLoginUrl)
            return;
        const canceller = new CancelHolder;
        const { protocol, host, pathname } = window.location;
        const redirectUrl = protocol + '//' + host + pathname;
        PublicGetWxLoginUrl.call({ state: redirectUrl }, canceller)
            .then(v => setWxLoginUrl(v.data))
            .catch(CancelHolder.ignoreCancelErr);
        return () => {
            canceller.cancel();
        };
    }, [wxLoginUrl]);
    return (React.createElement("div", { className: classNames('login_view', props.className) },
        viewStack.top === ViewType.ResetPassword &&
            React.createElement(FindPwd, { logic: resetPwdLogic, onClickBack: viewStack.pop, onConfirm: () => set_login_way(ViewType.ResetPassword) }),
        (viewStack.top === ViewType.MobileLogin || viewStack.top === ViewType.PasswordLogin) &&
            React.createElement("div", { className: 'main_wrap' },
                React.createElement("div", { className: 'tabs' },
                    React.createElement("button", { type: 'button', className: 'btn_tab', disabled: viewStack.top === ViewType.PasswordLogin, onClick: () => viewStack.reset([ViewType.PasswordLogin]) }, t('login.tab_pwd_login')),
                    React.createElement("button", { type: 'button', className: 'btn_tab', disabled: viewStack.top === ViewType.MobileLogin, onClick: () => viewStack.reset([ViewType.MobileLogin]) }, t('login.tab_sms_login'))),
                viewStack.top === ViewType.PasswordLogin &&
                    React.createElement(PwdLogin, { logic: pwdLoginLogic, onAOK: () => set_login_way(ViewType.PasswordLogin), onClickResetPassword: () => viewStack.push(ViewType.ResetPassword) }),
                viewStack.top === ViewType.MobileLogin &&
                    React.createElement(MobileLogin, { logic: smsLoginLogic, onAOK: () => set_login_way(ViewType.MobileLogin) }),
                React.createElement("div", { className: 'wechat_btn_row', onClick: () => viewStack.push(ViewType.WechatLogin) },
                    React.createElement("img", { src: require('@/assets/images/wechat@2x.png') }),
                    React.createElement("span", null, t('login.wechat_login')))),
        (wxLoginUrl && viewStack.top === ViewType.WechatLogin) &&
            React.createElement("div", { className: 'wechat_login_view' },
                React.createElement("iframe", { className: 'inner_iframe', src: wxLoginUrl }),
                React.createElement(CloseOutlined, { className: 'btn_close_wx_login', onClick: viewStack.pop })),
        viewStack.top === ViewType.SelectOrganiztion &&
            React.createElement(SelectOrg, { orgList: orgList, loading: isOrgListLoading, onClickOrgItem: onOrgSelected, onClickReturn: () => {
                    viewStack.pop();
                    set_login_way(void 0);
                }, error: orgListError })));
}
export default LoginView;
