/******************************************************************
 * Copyright @ 2023 广州牛师帮文化科技有限公司. All rights reserverd.
 * @file   src\routes\Login\PwdLogin\index.tsx
 * @author Gim
 * @date   2023/01/06 14:55
 * @desc   账号密码登录UI
 ******************************************************************/
import * as i18n from '@/i18n';
import imgUserName from '@assets/images/Man@2x.png';
import imgPassword from '@assets/images/Med_kit@2x.png';
import { Checkbox, Form, Input } from 'antd';
import React from 'react';
import './index.less';
export default function PwdLogin(props) {
    const { t } = i18n.useTranslation();
    const { logic } = props;
    const [form] = Form.useForm();
    const aok = logic.aok;
    return (React.createElement("div", { className: 'pwd_login_view' },
        React.createElement(Form, { form: form, initialValues: { userName: logic.userName, password: logic.password } },
            React.createElement(Form.Item, { name: 'userName' },
                React.createElement(Input, { prefix: React.createElement("img", { className: 'prefix', src: imgUserName }), placeholder: t("login.username"), bordered: false, autoComplete: 'username', onChange: v => logic.setUserName(v.target.value.trim()) })),
            React.createElement(Form.Item, { name: 'password' },
                React.createElement(Input.Password, { prefix: React.createElement("img", { className: 'prefix', src: imgPassword }), placeholder: t("login.password"), bordered: false, onPressEnter: () => aok && props.onAOK(), onChange: v => logic.setPassword(v.target.value.trim()), autoComplete: 'current-password' }))),
        React.createElement("div", { className: 'other' },
            React.createElement(Checkbox, { className: 'ckb_is_auto_login', checked: logic.isAutoLogin, onChange: v => logic.setIsAutoLogin(v.target.checked) }, t("login.auto_login_within_some_time")),
            React.createElement("button", { type: 'button', className: 'btn_forget_pwd', onClick: props.onClickResetPassword }, t("login.forget_password"))),
        React.createElement("button", { type: 'button', className: 'btn_login', onClick: props.onAOK, disabled: !aok }, t("login.btn_login"))));
}
