//// Generated by Torna Api Stealer. DO NOT EDIT IT DIRECTLY!
//// @name         微信登录-获取授权URL
//// @author       
//// @see          https://api-doc.niushibang.net/#/view/r2jDdKXG
//// 
//// @docId        r2jDdKXG
//// @docUrl       https://api-doc.niushibang.net
//// @baseUrl      
//// @apiUrl       /public/getWxLoginUrl
//// @envs         
////               [dev] https://b-api-dev.niushibang.net/userCenter
//// @httpMethod   GET
//// @description  扫码后会跳转到回调地址，并带上code，若?code=authdeny 则用户拒绝授权，若同意则将code传给登录接口
//// @createTime   2023-01-03 14:13:00
//// @lastModified 2023-01-11 14:59:43 
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { apiHttp } from '@/network/ApiHttp';
import { checkReply } from './checkReply';
export var PublicGetWxLoginUrl;
(function (PublicGetWxLoginUrl) {
    function call(params, canceller) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = __SERVER__ + "/public/getWxLoginUrl";
            const reply = yield apiHttp["GET"](Object.assign({ url, params: params, headers: undefined, data: undefined }, canceller === null || canceller === void 0 ? void 0 : canceller.signals()));
            return checkReply(reply);
        });
    }
    PublicGetWxLoginUrl.call = call;
})(PublicGetWxLoginUrl || (PublicGetWxLoginUrl = {}));
