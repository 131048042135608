import CONTROL_ACTION_TYPES from '../constant/action-types/control';
const initialState = {
    spinning: false,
    isShowFindPassword: false, // 跳到该路径
};
export default function route(state = initialState, { type, payload }) {
    switch (type) {
        case CONTROL_ACTION_TYPES.IS_SHOW_FIND_PASSWORD:
            return Object.assign(Object.assign({}, state), { isShowFindPassword: payload.isShowFindPassword });
        case CONTROL_ACTION_TYPES.IS_SHOW_LOADING:
            return Object.assign(Object.assign({}, state), { spinning: payload.spinning });
    }
    return state;
}
