/******************************************************************
 * Copyright @ 2022 广州牛师帮文化科技有限公司. All rights reserverd.
 * @file   src\i18n\index.ts
 * @author Gim
 * @date   2022/12/29 22:13
 * @desc   i18n 初始化
 ******************************************************************/
import i18n_ from "i18next";
import * as reactI18N from "react-i18next";
import ZHCN from '@/../locales/zh/cn.json';
export const fallbackLng = 'zh-CN';
export const resources = {
    "zh-CN": { translation: ZHCN }
};
i18n_.use(reactI18N.initReactI18next).init({
    resources,
    fallbackLng,
    interpolation: {
        /*
        Note: react already safes from xss
        https://www.i18next.com/translation-function/interpolation#unescape
        */
        escapeValue: false
    }
});
/* 直接用
import { i18n } from '@/i18n;
i18n.t("key");
*/
export const i18n = i18n_;
/* hooks 方式使用
import { useTranslation } from '@/i18n;
function Component(){
  const { t } = useTranslation();
  return <div>{t("key")}</div>
}
*/
export const useTranslation = reactI18N.useTranslation;
