export var Helper;
(function (Helper) {
    function getClearParams(obj) {
        const ret = Object.assign({}, obj);
        if (ret) {
            const keys = Object.keys(ret);
            for (let i = 0; i < keys.length; ++i) {
                const key = keys[i];
                if (ret[key] === null || ret[key] === undefined || ret[key] === '')
                    delete ret[key];
            }
        }
        return ret;
    }
    Helper.getClearParams = getClearParams;
})(Helper || (Helper = {}));
