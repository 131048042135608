var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AxiosHeaders } from "axios";
export var ContentType;
(function (ContentType) {
    function parse(headersOrRawStr) {
        return __awaiter(this, void 0, void 0, function* () {
            const rawStr = typeof headersOrRawStr === 'string' ?
                headersOrRawStr :
                headersOrRawStr instanceof AxiosHeaders ?
                    headersOrRawStr.get('content-type') :
                    headersOrRawStr['content-type'];
            const ret = {
                type: '',
                parameters: {}
            };
            if (typeof rawStr === 'string') {
                const parts = rawStr.split(';')
                    .map(v => v.trim())
                    .filter(v => v);
                ret.type = parts[0];
                const len = parts.length;
                for (let i = 1; i < len; ++i) {
                    const result = parts[i].match(/(.*?)=(.*?)$/);
                    if (!result) {
                        continue;
                    }
                    ret.parameters[result[1]] = result[2];
                }
            }
            return ret;
        });
    }
    ContentType.parse = parse;
})(ContentType || (ContentType = {}));
