import loadable from "@loadable/component";
import React, { useCallback } from "react";
import { UserPermission } from "./hooks/UserPermission";
import { RouteGuard } from "./components/RouteGuard";
export var Paths;
(function (Paths) {
    /**
     * 路由配置
     */
    let All;
    (function (All) {
        All["ROOT"] = "/";
        All["LOGIN"] = "/login";
        All["SSO_VIEW"] = "/sso_view";
        All["SSO_PAGE"] = "/sso_page";
        All["SSO_LOGOUT"] = "/logout";
        All["MAIN"] = "/PC";
        All["TEST"] = "/PC/test";
        // 教师列表
        All["TEACHER"] = "/PC/teacher";
        All["TEACHER_LIST"] = "/PC/teacher/teacher_list";
        All["TEACHER_DETAIL"] = "/PC/teacher/teacher_detail";
        // 审核列表
        All["AUDIT_LIST"] = "/PC/teacher/audit_list";
        All["AUDIT_OPERATION"] = "/PC/teacher/audit_operation";
        // 介绍审核
        All["INTRODUCE_AUDIT"] = "/PC/teacher/introduce_audit";
        All["INTRODUCE_AUDIT_OPERATION"] = "/PC/teacher/introduce_operation";
        // 组织架构
        All["AUTH"] = "/PC/auth";
        All["AUTH_DEPARTMENT"] = "/PC/auth/auth_department";
        All["AUTH_STAFF"] = "/PC/auth/auth_staff";
        All["AUTH_ROLE"] = "/PC/auth/auth_role";
        All["AUTH_POST"] = "/PC/auth/auth_post";
        // 系统设置
        All["SYSTEM_SETTING"] = "/PC/system_setting";
        All["SENSITIVE_SETTING"] = "/PC/sensitive_setting";
        // 个人中心
        All["USER_CENTER"] = "/PC/user_center";
        All["USER_CENTER_BASEINFO"] = "/PC/user_center/baseInfo";
        All["USER_CENTER_EDIT_PWD"] = "/PC/user_center/edit_pwd";
        All["USER_CENTER_EXPORT_RECORD"] = "/PC/user_center/export_record";
        All["USER_CENTER_BIND"] = "/PC/user_center/bind";
        All["USER_CENTER_VERIFY"] = "/PC/user_center/verify";
        All["USER_CENTER_VERIFY_DETAIL"] = "/PC/user_center/verify/detail";
        All["USER_CENTER_COMPLETE_PROFILE"] = "/PC/user_center/complete_profile";
        All["USER_CENTER_MY_MESSAGE"] = "/PC/user_center/my_message";
    })(All = Paths.All || (Paths.All = {}));
    Paths.Titles = {
        [All.ROOT]: "用户中心",
        [All.LOGIN]: "用户中心登录",
        [All.SSO_VIEW]: "用户中心登录",
        [All.SSO_PAGE]: "用户中心登录",
        [All.SSO_LOGOUT]: "用户中心登出",
        [All.TEST]: "",
        [All.TEACHER]: "指导管理",
        [All.TEACHER_LIST]: "指导列表",
        [All.TEACHER_DETAIL]: "指导员详情",
        [All.AUDIT_LIST]: "资质审核",
        [All.AUDIT_OPERATION]: "审核详情",
        [All.INTRODUCE_AUDIT]: "介绍审核",
        [All.INTRODUCE_AUDIT_OPERATION]: "个人介绍审核",
        [All.AUTH]: "组织架构",
        [All.AUTH_DEPARTMENT]: "部门管理",
        [All.AUTH_STAFF]: "员工管理",
        [All.AUTH_ROLE]: "角色管理",
        [All.AUTH_POST]: "岗位管理",
        [All.SYSTEM_SETTING]: "系统设置",
        [All.SENSITIVE_SETTING]: "敏感词设置",
        [All.USER_CENTER]: "个人中心",
        [All.USER_CENTER_BASEINFO]: "个人信息",
        [All.USER_CENTER_EDIT_PWD]: "修改密码",
        [All.USER_CENTER_EXPORT_RECORD]: "导出记录",
        [All.USER_CENTER_BIND]: "微信绑定",
        [All.USER_CENTER_VERIFY]: "认证信息",
        [All.USER_CENTER_VERIFY_DETAIL]: "",
        [All.USER_CENTER_COMPLETE_PROFILE]: "资料完善",
        [All.USER_CENTER_MY_MESSAGE]: "我的消息",
        [All.MAIN]: ""
    };
    Paths.Components = {
        [All.ROOT]: undefined,
        [All.MAIN]: loadable(() => import('@/routes/PC/Main')),
        [All.LOGIN]: loadable(() => import("@routes/PC/Login")),
        [All.SSO_VIEW]: loadable(() => import('@/routes/SSO/View')),
        [All.SSO_PAGE]: loadable(() => import('@/routes/SSO/Page')),
        [All.SSO_LOGOUT]: loadable(() => import('@/routes/SSO/Logout')),
        [All.TEST]: undefined,
        [All.TEACHER]: undefined,
        [All.TEACHER_LIST]: loadable(() => import('@/routes/PC/TeacherManagement/TeacherList')),
        [All.TEACHER_DETAIL]: loadable(() => import('@/routes/PC/TeacherManagement/TeacherList/TeacherDetail')),
        [All.AUDIT_LIST]: loadable(() => import('@/routes/PC/TeacherManagement/QualificationAudit')),
        [All.AUDIT_OPERATION]: loadable(() => import('@/routes/PC/TeacherManagement/QualificationAudit/AuditDetail')),
        [All.INTRODUCE_AUDIT]: loadable(() => import('@/routes/PC/TeacherManagement/IntroduceAudit')),
        [All.INTRODUCE_AUDIT_OPERATION]: loadable(() => import('@/routes/PC/TeacherManagement/IntroduceAudit/AuditDetail')),
        [All.AUTH]: undefined,
        [All.AUTH_DEPARTMENT]: loadable(() => import('@/routes/PC/Stucture/Department')),
        [All.AUTH_STAFF]: loadable(() => import('@/routes/PC/Stucture/Staff')),
        [All.AUTH_ROLE]: loadable(() => import('@/routes/PC/Stucture/Role')),
        [All.AUTH_POST]: loadable(() => import('@/routes/PC/Stucture/Post')),
        [All.SYSTEM_SETTING]: undefined,
        [All.SENSITIVE_SETTING]: loadable(() => import('@/routes/PC/SystemSetting/SensitiveWordSetting')),
        [All.USER_CENTER]: loadable(() => import('@/routes/PC/User')),
        [All.USER_CENTER_BASEINFO]: loadable(() => import('@/components/UserCenterTabs/BaseInfo')),
        [All.USER_CENTER_EDIT_PWD]: loadable(() => import('@/components/UserCenterTabs/EditPwd')),
        [All.USER_CENTER_EXPORT_RECORD]: loadable(() => import('@/components/UserCenterTabs/ExportRecord')),
        [All.USER_CENTER_BIND]: loadable(() => import('@/components/UserCenterTabs/Bind')),
        [All.USER_CENTER_VERIFY]: loadable(() => import('@/components/UserCenterTabs/Authentic')),
        [All.USER_CENTER_VERIFY_DETAIL]: undefined,
        [All.USER_CENTER_COMPLETE_PROFILE]: loadable(() => import('@/components/UserCenterTabs/ProfileComplete')),
        [All.USER_CENTER_MY_MESSAGE]: loadable(() => import('@/components/UserCenterTabs/MyMessage')),
    };
    Paths.Icons = {
        [All.ROOT]: undefined,
        [All.LOGIN]: undefined,
        [All.SSO_VIEW]: undefined,
        [All.SSO_PAGE]: undefined,
        [All.SSO_LOGOUT]: undefined,
        [All.TEST]: undefined,
        [All.TEACHER]: require('@/assets/images/menu_icon1@2x.png'),
        [All.TEACHER_LIST]: undefined,
        [All.TEACHER_DETAIL]: undefined,
        [All.AUDIT_LIST]: undefined,
        [All.AUDIT_OPERATION]: undefined,
        [All.INTRODUCE_AUDIT]: undefined,
        [All.INTRODUCE_AUDIT_OPERATION]: undefined,
        [All.AUTH]: require('@/assets/images/menu_icon3@2x.png'),
        [All.AUTH_DEPARTMENT]: undefined,
        [All.AUTH_STAFF]: undefined,
        [All.AUTH_ROLE]: undefined,
        [All.AUTH_POST]: undefined,
        [All.SYSTEM_SETTING]: require('@/assets/images/menu_icon2@2x.png'),
        [All.SENSITIVE_SETTING]: undefined,
        [All.USER_CENTER]: undefined,
        [All.USER_CENTER_BASEINFO]: undefined,
        [All.USER_CENTER_EDIT_PWD]: undefined,
        [All.USER_CENTER_EXPORT_RECORD]: undefined,
        [All.USER_CENTER_BIND]: undefined,
        [All.USER_CENTER_VERIFY]: undefined,
        [All.USER_CENTER_VERIFY_DETAIL]: undefined,
        [All.USER_CENTER_COMPLETE_PROFILE]: undefined,
        [All.USER_CENTER_MY_MESSAGE]: undefined,
        [All.MAIN]: undefined
    };
    Paths.Childrens = {
        [All.ROOT]: [
            All.LOGIN,
            All.SSO_VIEW,
            All.SSO_PAGE,
            All.SSO_LOGOUT,
            All.MAIN,
        ],
        [All.MAIN]: [
            All.USER_CENTER,
            All.TEACHER_LIST,
            All.TEACHER_DETAIL,
            All.AUDIT_LIST,
            All.AUDIT_OPERATION,
            All.INTRODUCE_AUDIT,
            All.INTRODUCE_AUDIT_OPERATION,
            All.AUTH_DEPARTMENT,
            All.AUTH_STAFF,
            All.AUTH_ROLE,
            All.AUTH_POST,
            All.SENSITIVE_SETTING,
        ],
        [All.USER_CENTER]: [
            All.USER_CENTER_BASEINFO,
            All.USER_CENTER_EDIT_PWD,
            All.USER_CENTER_EXPORT_RECORD,
            All.USER_CENTER_BIND,
            All.USER_CENTER_VERIFY,
            All.USER_CENTER_COMPLETE_PROFILE,
            All.USER_CENTER_MY_MESSAGE
        ]
    };
    /**
     * 访问一个路径需要的权限
     *    值 === undefined // 表示不需要登录也能访问
     *    值 === ""        // 表示访问此路径只需要登录
     *    值 === "A,B"     // 表示访问此路径需要A权限与B权限
     */
    Paths.Permissions = {
        [All.ROOT]: undefined,
        [All.LOGIN]: undefined,
        [All.SSO_VIEW]: undefined,
        [All.SSO_PAGE]: undefined,
        [All.SSO_LOGOUT]: undefined,
        [All.MAIN]: "",
        [All.TEST]: "",
        [All.TEACHER]: "yhzx_teacher_manage",
        [All.TEACHER_LIST]: "yhzx_teacher_list",
        [All.TEACHER_DETAIL]: "yhzx_teacher_list",
        [All.AUDIT_LIST]: "yhzx_qualification_review",
        [All.AUDIT_OPERATION]: "yhzx_qualification_review",
        [All.INTRODUCE_AUDIT]: "yhzx_introduce_review",
        [All.INTRODUCE_AUDIT_OPERATION]: "yhzx_introduce_review",
        [All.AUTH]: "yhzx_org_structure",
        [All.AUTH_DEPARTMENT]: "yhzx_department_manage",
        [All.AUTH_STAFF]: "yhzx_employee_manage",
        [All.AUTH_ROLE]: "yhzx_role_manage",
        [All.AUTH_POST]: "yhzx_position_manage",
        [All.SYSTEM_SETTING]: "yhzx_sys_manage",
        [All.SENSITIVE_SETTING]: "yhzx_sensitive_setting",
        [All.USER_CENTER]: "",
        [All.USER_CENTER_BASEINFO]: "",
        [All.USER_CENTER_EDIT_PWD]: "",
        [All.USER_CENTER_EXPORT_RECORD]: "",
        [All.USER_CENTER_BIND]: "",
        [All.USER_CENTER_VERIFY]: "",
        [All.USER_CENTER_VERIFY_DETAIL]: "",
        [All.USER_CENTER_COMPLETE_PROFILE]: "",
        [All.USER_CENTER_MY_MESSAGE]: ""
    };
    Paths.Fallbacks = [
        All.TEACHER_LIST,
        All.AUDIT_LIST,
        All.INTRODUCE_AUDIT,
        All.AUTH_DEPARTMENT,
        All.AUTH_STAFF,
        All.AUTH_ROLE,
        All.AUTH_POST,
        All.SENSITIVE_SETTING
    ];
    function Routes(props) {
        const paths = Paths.Childrens[props.path];
        if (!(paths === null || paths === void 0 ? void 0 : paths.length)) {
            return React.createElement(React.Fragment, null);
        }
        const elements = paths.map(path => React.createElement(RouteGuard.Route, { key: path, path: path }));
        return React.createElement(React.Fragment, null, elements);
    }
    Paths.Routes = Routes;
    ;
    function usePermission() {
        const checker = UserPermission.use();
        return useCallback((pathname) => {
            return checker(Paths.Permissions[pathname]);
        }, [checker]);
    }
    Paths.usePermission = usePermission;
})(Paths || (Paths = {}));
