/******************************************************************
 * Copyright @ 2023 广州牛师帮文化科技有限公司. All rights reserverd.
 * @file   src\routes\Login\SelectOrg\index.tsx
 * @author 朱剑豪
 * @date   2023/01/06 17:15
 * @desc   选择机构UI
 ******************************************************************/
import React from 'react';
import './index.less';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
export default function SelectOrg(props) {
    const { error } = props;
    const { t } = useTranslation();
    const renderItem = (item) => {
        return (React.createElement("div", { key: item.id, className: 'org_item', onClick: () => props.onClickOrgItem(item) }, item.name));
    };
    return (React.createElement("div", { className: 'select_org_view' },
        React.createElement("h2", { className: 'subtitle' }, t('login.select_org')),
        React.createElement("div", { className: 'org_list' },
            error ? ((error === null || error === void 0 ? void 0 : error.message) || '' + error) : '',
            !props.loading ? null : React.createElement(LoadingOutlined, { className: 'loading' }),
            props.orgList.map(renderItem)),
        React.createElement("div", { onClick: props.onClickReturn, className: 'btn_back' }, t('login.btn_back'))));
}
