import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Paths } from "./Paths";
import { useLoginInfo } from "./routes/Login/logic/useLoginLogic";
export function AuthChecker() {
    const history = useHistory();
    const token = useLoginInfo('token');
    const path = history.location.pathname;
    useEffect(() => {
        if (token || Paths.Permissions[path] === undefined)
            return;
        history.replace(Paths.All.LOGIN, { redirect: path });
    }, [path, token]);
    return React.createElement(React.Fragment, null);
}
